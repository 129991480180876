/* eslint-disable react/no-unescaped-entities */
import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import { Trans, t } from "@lingui/macro";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Box from "@material-ui/core/Box";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import SearchIcon from "@material-ui/icons/Search";
import theme from "../../theme";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    height: "100%",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  searchFaqs: {
    marginBottom: theme.spacing(2),
    width: 250,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  faqTitle: {
    fontSize: "24px",
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  accordion: {
    marginBottom: theme.spacing(2),
  },
  question: {
    fontWeight: "bold",
  },
  resume: {
    fontStyle: "italic",
    marginTop: theme.spacing(2),
  },
}));

const faqs = [
  {
    id: 1,
    question: t`What is Book a Corner?`,
    answer: t`Book A Corner is a digital solution designed for the lease of commercial spaces. It streamlines the
    short-term leasing processes for landlords and brokers, offering a powerful software platform for managing
    and leasing assets securely and efficiently.`,
  },
  {
    id: 2,
    question: t`What features does Book a Corner offer?`,
    answer: t`Book A Corner provides landlords and tenants with the ability to offer and rent commercial spaces easily,
    manage 100% of their bookings, maintain direct communication, reduce booking time, make one-click
    payments, keep operations traceable, analyze asset performance, and enhance user experience.`,
  },
  {
    id: 3,
    question: t`How do I create a Book A Corner account?`,
    answer: t`If you don't have a Book A Corner account, simply visit the URL and click on "Sign up." Follow the
    two-step process: provide company details (country of residence, tax identification number, company/agency
    name) and user details (name, surname, phone number, email, and password). Make sure to read and accept
    the terms and conditions. Once completed, click "Sign up," and you'll receive a confirmation email.`,
  },
  {
    id: 4,
    question: t`How do I find available spaces on Book a Corner?`,
    answer: t`To find spaces, enter the desired shopping center in the search bar, specify start and exit dates, and
    click "search." Alternatively, select the shopping center directly from the map. Once selected, explore
    available spaces based on categories like Pop-Up Shop, CML Kiosk, or Advertising.`,
  },
  {
    id: 5,
    question: t`How do I make a request or start the booking process for a space?`,
    answer: t`For spaces, after selecting the desired space, choose the dates, view the price, and select "Ask landlord"
    to initiate the booking process. For ad spaces, select dates and spaces, click "Accept," and proceed with
    the request. Complete the details of your project as requested by the landlord.`,
  },
  {
    id: 6,
    question: t`How do I manage my requests and bookings on Book a Corner?`,
    answer: t`Access the Control Panel by clicking on the upper right corner. You can edit personal information, change
    passwords, and check notifications in the "Profile" option. Track requests and bookings under "My
    requests," where you can view pending requests, upcoming bookings, active bookings, completed bookings,
    and canceled requests.`,
  },
  {
    id: 7,
    question: t`How can I communicate with the shopping center manager?`,
    answer: t`Within your requests, click on any request to access details. You can use the chat feature to communicate
    directly with the shopping center manager. This enables you to ask questions, send and receive
    attachments, and review the entire booking history.`,
  },
  {
    id: 8,
    question: t`Can I modify booking dates?`,
    answer: t`Yes, you can change dates by clicking on the "Change dates" button. Choose the new required period, view
    the new price, and select "Change dates." Note that this option is only available before signing the
    contract.`,
  },
  {
    id: 9,
    question: t`What is the step-by-step process for booking?`,
    answer: t`The booking process involves requesting approval, signing the contract, and making payment. After
    selecting a space, click "Request booking." Once approved, the "Book" option becomes available. You can
    download the proforma, sign the contract digitally or manually, and finally, make the payment.`,
  },
  {
    id: 10,
    question: t`What payment methods are supported on Book a Corner?`,
    answer: t`Book A Corner supports integrated payment gateways (credit card) for online payments. If not available,
    payment by bank transfer is an option, and you'll need to upload and confirm the payment receipt.
    Supported file types for uploads are PDF, JPG, and PNG.`,
  },
  {
    id: 11,
    question: t`What happens after making the payment on Book a Corner?`,
    answer: t`After a successful payment, a confirmation message will appear in the chat. If all steps were completed
    correctly, the booking will be confirmed, and the process will be considered completed. You can find the
    details of the booking under the "Bookings to start" tab in your control panel.`,
  },
  {
    id: 12,
    question: t`How can I access contract documents and payment details?`,
    answer: t`In the booking details, you can access important information by navigating to the "Bookings to start" tab.
    Two buttons will be enabled: Contract Documents: Clicking on this option allows you to download the
    contract and other relevant documents at any time. Payments and Fees: By selecting this option, you can
    view detailed information related to payments, including dates, amounts, payment status, and invoices.
    Invoices can be downloaded as soon as the manager uploads them from their profile.`,
  },
  {
    id: 13,
    question: t`How can I finish a contract on Book a Corner?`,
    answer: t`To request the termination of a contract, go to your control panel and click on the "Payments and fees"
    button associated with the relevant booking. From there, you can request to finish the contract. The
    manager will then be responsible for ensuring the correct termination of the contract, if applicable. A
    new interaction will be automatically recorded in the chat to document the process.`,
  },
];

const FaqsComponent = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const classes = useStyles(theme);

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredFaqs = faqs.filter(
    (faq) =>
      faq.question.toLowerCase().includes(searchTerm.toLowerCase()) ||
      faq.answer.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Container className={classes.container}>
      <Box className={classes.searchContainer}>
        <Typography variant="h4" className={classes.faqTitle}>
          <Trans>FAQs for Book a Corner: A Digital Solution for Commercial Space Leasing</Trans>
        </Typography>

        <TextField
          className={classes.searchFaqs}
          label="Search FAQs"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchTermChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon color="action" />
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {filteredFaqs.map((faq) => (
        <Accordion key={faq.id} className={classes.accordion}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography className={classes.question}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}

      <Typography variant="body1" className={classes.resume}>
        <Trans>
          These steps ensure transparency and provide a seamless experience throughout the leasing process on Book a
          Corner. If you encounter any issues or have additional questions, feel free to use the chat feature to
          communicate directly with the shopping center manager for assistance.
        </Trans>
      </Typography>
    </Container>
  );
};

export default FaqsComponent;
